$mct-blue: #2452ac;
$mct-grey: #63615f;
$mct-white: #fff;
$mct-black: #212529;
$mct-red: #da1f3d;
$mct-yellow: #f8e71b;
$mct-green: #02a053;
$font-family-base: 'Noto Sans', sans-serif;
$headings-font-weight: 700;

$theme-colors: (
  'primary': $mct-blue,
  'light': #dfeffd,
  'danger': $mct-red,
  'warning': $mct-yellow,
  'success': $mct-green
);

$container-max-widths: (
  sm: 1440px
);
