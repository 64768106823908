trix-content {
  display: block;
}

trix-toolbar.trix-toolbar--sticky {
  position: sticky;
  top: 0;
  background-color: white;
}

trix-toolbar .trix-button--icon-heading-2::before,
trix-toolbar .trix-button--icon-heading-3::before {
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12%209v3H9v7H6v-7H3V9h9zM8%204h14v3h-6v12h-3V7H8V4z%22%2F%3E%3C%2Fsvg%3E);
}

trix-toolbar .trix-button--icon-heading-2::before {
  transform: scale(0.8);
}

trix-toolbar .trix-button--icon-heading-3::before {
  transform: scale(0.7);
}
