body {
  background-color: $mct-grey;

  &.is-production {
    background-color: $mct-blue;
  }

  > .container {
    background-color: $mct-white;
  }

  &.dashboard-index > .container {
    background-color: transparent;
  }
}

body.static-page > .container {
  max-width: 760px;

  h1 { @extend .h2; }
  h2 { @extend .h4; }
}

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.card.is-disabled {
  pointer-events: none;
  opacity: 0.3;
}

a.card {
  text-decoration: none;
  transition: all, 0.2s;

  &:hover {
    transform: scale(1.02);
    text-decoration: none;
  }

  .card-text,
  .card-footer {
    color: $mct-black;
  }
}

.match-participant-name.bg-success,
.match-participant-name.bg-info {
  span,
  a,
  small,
  strong,
  .text-muted,
  .btn-link {
    color: $mct-white !important;
  }
}

.mw-sm {
  min-width: 80px;
}

.w-20 {
  width: 20%;
}

.pagination {
  flex-wrap: wrap;
}

.answer-badge {
  opacity: 0.8;
  font-weight: normal;
  margin-right: 5px;
  min-width: 40px;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.modal-dialog {
  max-width: 1000px;
}

.thumbnail {
  max-width: 350px;
  width: 100%;
  display: block;
  border: 2px solid currentColor;
  margin: 1em 0;
}

.small-text-override {
  .btn.js-modal {
    font-size: 80%;
    font-weight: normal;
  }
}
