/* stylelint-disable no-descending-specificity */
.legend__color {
  border-radius: 50%;
  height: 15px;
  opacity: 0.8;
  width: 15px;
}

.statistics-bar {
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  width: 100%;
}

.statistics-bar--alternate {
  .statistics-bar__block {
    &:nth-child(even) {
      opacity: 0.6;
    }
  }
}

.statistics-bar__block {
  color: white;
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  width: 100%;
}

.bg-blue {
  background-color: $mct-blue;
}

.bg-green {
  background-color: $mct-green;
}

.bg-yellow {
  background-color: $mct-yellow;
}

.bg-red {
  background-color: $mct-red;
}

.bg-black {
  background-color: $mct-black;
}
/* stylelint-enable no-descending-specificity */
