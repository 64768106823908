// TODO: figure out how to reach scss theme vars.
$mct-grey: #63615f;
$mct-red: #da1f3d;
$mct-green: #02a053;

.feedback-message {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.1rem;
  line-height: 1.5em;
  min-height: 3em;
  color: $mct-grey;
  padding-left: 30px;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: $mct-grey;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &.feedback-message__error {
    color: $mct-red;
  }

  &.feedback-message__error::before {
    background-color: $mct-red;
  }

  &.feedback-message__notice {
    color: $mct-green;
  }

  &.feedback-message__notice::before {
    background-color: $mct-green;
  }
}

// phone number input
.iti input {
  max-width: 12em;
}

.iti__flag-container {
  font-size: 1.2rem;
}

.btn__2fa-submit {
  font-size: 1em;
  padding: 10px 20px;
}

.form-control__verification-code {
  -moz-appearance: textfield;
  letter-spacing: 0.3em;
  max-width: 10em;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.masked-phone-number {
  font-size: 20px;

  a {
    font-size: 16px;
  }
}
