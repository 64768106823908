.field__color {
  height: 40px;
}

.invalid-feedback {
  order: 99;
}

select.form-control {
  &:disabled,
  &[readonly] {
    opacity: 0.2;
  }
}
